import React from "react"
import slugify from "src/utils/slugify"
import styled from "styled-components"

export interface Props {
  title: string
}

export default function PicksTitle({ title }: Props) {
  return (
    <GroupDiv>
      <ProfileDiv>
        <ProfileImg src={`/img/photo-${slugify(title)}.jpg`} alt={title} />
        <InnerShadowDiv />
      </ProfileDiv>
      <H1>
        <PicksBySpan>Picks by</PicksBySpan>
        {title}
      </H1>
    </GroupDiv>
  )
}

const GroupDiv = styled.div`
  display: flex;
  align-items: center;
`

const ProfileDiv = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: #eeeeee;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 14px;
  user-select: none;
`

const ProfileImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const InnerShadowDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 2px 12px rgba(0, 0, 0, 0.1);
`

const H1 = styled.h1`
  font-size: 27px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
  color: black;
  letter-spacing: 0.01em;
`

const PicksBySpan = styled.span`
  display: block;
  font-size: 13px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
`

const Separator = styled.span`
  display: none;
`

const SubTitleSpan = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
`
