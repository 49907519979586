import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import { contentPadding, listLink } from "src/styles/styleConstants"
import Markdown from "src/components/Markdown"

interface Props {
  id?: string
  text: string
  comment?: string
  link?: string
  iconUrl?: string
  category?: string
  isHovered?: boolean
  setHovered: (id: null | string) => void
}

export default function PicksListItem({
  id,
  text,
  comment,
  link,
  iconUrl,
  category,
  isHovered = false,
  setHovered,
}: Props) {
  return (
    <BlockLink
      to={link}
      onMouseEnter={() => setHovered(id)}
      onMouseLeave={() => setHovered(null)}
      isHovered={isHovered}
    >
      <Item>
        {iconUrl && <img src={iconUrl} alt={category} />}
        <div>{text}</div>
      </Item>
      {comment && (
        <CommentDiv>
          <Markdown>{comment}</Markdown>
        </CommentDiv>
      )}
    </BlockLink>
  )
}

const hoverStyle = css`
  background: rgba(0, 0, 0, 0.02);
`

const BlockLink = styled(Link)<{ isHovered: boolean }>`
  display: block;
  padding: 5px 0;
  transition: background-color 150ms;
  ${(p) => (p.isHovered ? hoverStyle : "")}

  @media (hover: hover) {
    &:hover {
      ${hoverStyle}
    }
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  min-height: 44px;
  padding-left: ${contentPadding}px;
  padding-right: ${contentPadding}px;
  color: ${listLink};

  img {
    width: 28px;
    height: 28px;
    vertical-align: middle;
  }

  div {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 500;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const CommentDiv = styled.div`
  margin-top: -5px;
  margin-left: ${31 + contentPadding}px;
  margin-right: ${contentPadding}px;
  margin-bottom: 10px;
  background: rgba(207, 207, 207, 0.33);
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
`
