import React, { ReactElement, useEffect } from "react"
import styled from "styled-components"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"

import ContentContainer from "src/containers/ContentContainer"
import BackLink from "src/components/BackLink"
import List from "src/components/List"
import Footer from "src/components/Footer"

import { picksStorePath } from "src/utils/paths"
import * as assetHelper from "src/utils/assetHelper"

import { Store, PicksSet } from "src/gatsby-node/data/dataSchemaV1"
import SEO from "src/components/SEO"
import { contentMarginsCss } from "src/styles/styleConstants"
import { Category } from "src/types"
import PicksTitle from "./PicksTitle"
import Space from "src/components/Space"
import HorizontalRule from "src/components/HorizontalRule"
import PicksListItem from "./PicksListItem"
import Markdown from "src/components/Markdown"

export interface PicksProps {
  stores: {
    names: Array<string>
    byName: {
      [id: string]: Store
    }
  }
  category: Category
  picks: PicksSet
  storeFocused: string
  storeHovered: string
  setHovered: (id: string | null) => void
  setImplicitCategory: Function
}

export default function Picks({
  stores,
  category,
  picks,
  storeHovered,
  setHovered,
  setImplicitCategory,
}: PicksProps): ReactElement {
  useEffect(() => {
    // Set implicit category (if click other stores)
    setImplicitCategory(category)
  }, [])

  useEffect(() => {
    // Clear any active mouse hover when leaving
    return () => setHovered(null)
  }, [])

  const categoryTitle = "Picks by " + category.name
  let dateString
  if (picks.shownUpdatedDate) {
    dateString = "Last updated on " + formatDateStr(picks.shownUpdatedDate)
  } else if (picks.shownPublishDate) {
    dateString = "Published on " + formatDateStr(picks.shownPublishDate)
  }

  return (
    <>
      <SEO title={categoryTitle} keywords={[category.name]} />

      <ContentContainer>
        {/* This div is needed for sticky to work on Safari */}
        <div>
          <BackLink link="/" text="Back" />
          <TitleArea>
            <PicksTitle title={category.name} />
            <Space v={20} />
            <Description>
              <Markdown>{picks.personDescription}</Markdown>
            </Description>
            <Space v={20} />
            <HorizontalRule />
          </TitleArea>
          <List>
            {picks.stores.map(({ name, comment }) => {
              const store = stores.byName[name]
              if (!store) return null
              const categoryName = category.name ?? ""
              return (
                <PicksListItem
                  key={name}
                  id={name}
                  text={name}
                  comment={comment}
                  link={picksStorePath(categoryName, name)}
                  iconUrl={assetHelper.iconAssetUrlForTags(store.tags ?? [])}
                  category={store.tags?.[0]}
                  isHovered={store.name === storeHovered}
                  setHovered={setHovered}
                />
              )
            })}
          </List>

          <Space v={10} />
          {dateString && <DateDiv>{dateString}</DateDiv>}
          <Space v={35} />
        </div>
      </ContentContainer>

      <Footer />
    </>
  )
}

const TitleArea = styled.div`
  ${contentMarginsCss}
  padding-top: 20px;
`

const Description = styled.div`
  font-size: 15px;
  line-height: 1.3;
`

const DateDiv = styled.div`
  ${contentMarginsCss}
  font-size: 12px;
  font-weight: normal;
  color: black;
  opacity: 0.4;
`

function formatDateStr(date: string): string {
  return format(parseISO(date), "MMM d, y")
}
